<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              :disabled="disabled"
              v-bind="attrs"
              fab
              :color="color"
              v-on="{ ...tooltip, ...menu}"
            >
              <v-icon>{{ icon }}</v-icon>
            </v-btn>

          </template>
          <span>{{ tooltipLabel }}</span>
        </v-tooltip>

      </template>
      <v-list>
        <v-list-item v-for="(item,index) in items" :key="index" @click="sendEvent(item)">
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
export default {
  name: 'DropDownList',
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    tooltipLabel: {
      type: String,
      default() {
        return ''
      }
    },
    icon: {
      type: String,
      default() {
        return 'mdi-list-status'
      }
    },
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    },
    color: {
      type: String,
      default() {
        return null
      }
    }
  },
  methods: {
    sendEvent(value) {
      this.$emit('onSelect', value)
    }
  }
}
</script>
