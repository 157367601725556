<template>
  <div>
    <h1 class="text-h4">Управление продажами</h1>
    <v-divider class="my-2"></v-divider>
    <v-card>
      <v-card-title>
        <v-sheet width="100%">
          <v-row>
            <v-col>
              <v-combobox
                v-model="table.filter.orderStatus"
                small-chips
                dense
                label="Статус заказа"
                persistent-hint
                chips
                deletable-chips
                clearable
                :items="orderStatusOptions"
                multiple
                :loading="busy"
                @input="onInputFilter"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row no-gutters dense>
            <v-col cols="12" sm="3">
              <v-select
                v-model="table.filter.source"
                return-object
                :items="sourceOptions"
                :disabled="busy"
                label="Источник"
                @change="onInputFilter"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3" class="pl-sm-1">
              <v-select
                v-model="table.filter.deliveryType"
                return-object
                :items="deliveryTypeOptions"
                :disabled="busy"
                label="Получение"
                @change="onInputFilter"
              ></v-select>
            </v-col>
            <v-col class="d-flex justify-end pl-sm-1" cols="12" sm="6">
              <v-text-field
                v-model="table.filter.search"
                append-icon="mdi-magnify"
                label="Поиск"
                single-line
                hide-details
                clearable
                @input="onInputFilter"
              ></v-text-field>
              <v-btn icon class="ml-2 mt-1" outlined @click="toggleAdvancedSearch">
                <v-icon>{{ showAdvancedSearch ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-sheet>
      </v-card-title>
      <transition name="slide">
        <v-card-text v-show="showAdvancedSearch">
        </v-card-text>
      </transition>
      <v-card-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              color="warning"
              :disabled="busy"
              v-bind="attrs"
              @click="resetFilter"
              v-on="on"
            >
              <v-icon>mdi-filter-variant-remove</v-icon>
            </v-btn>
          </template>
          <span>Сбросить фильтр</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Manager'}"
              fab
              color="success"
              :disabled="busy"
              v-bind="attrs"
              :to="{ name: 'order.create' }"
              v-on="on"
            >
              <v-icon>mdi-file-plus-outline</v-icon>
            </v-btn>
          </template>
          <span>Новый заказ</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-can:access="{type:'acl', id:'Admin'}"
              fab
              color="error"
              :disabled="!hasSelected"
              class="ml-1"
              v-bind="attrs"
              v-on="on"
              @click="deleteSelectedAction"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>Удалить группу</span>
        </v-tooltip>
        <drop-down-list
          class="ml-1"
          :items="orderStatusOptions"
          tooltip-label="Изменить статус"
          :disabled="!hasSelected"
          color="warning"
          @onSelect="setStatusAction"
        ></drop-down-list>
      </v-card-actions>
      <v-data-table
        v-model="table.selectedItems"
        :loading="busy"
        :headers="table.headers"
        :items="items"
        :server-items-length="itemsTotalCount"
        :footer-props="table.footerProps"
        :options.sync="table.options"
        show-select
      >
        <template v-slot:item.code="{ item }">
          <router-link :to="{ name:'order.view', params: {id: item.id} }">{{ item.code }}</router-link>
        </template>

        <template v-slot:item.deliveryType="{ item }">
          <v-icon v-if="item.deliveryType==='Pickup'" color="info">
            mdi-run
          </v-icon>
          <v-icon v-else-if="item.deliveryType==='Delivery'" color="success">
            mdi-truck-outline
          </v-icon>
          <v-icon v-else color="warning">
            mdi-help-circle-outline
          </v-icon>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip :color="orderStatusColor(item.status)" small>{{ item.status | orderStatusTitle }}</v-chip>
        </template>
        <template v-slot:item.meta.createdAt="{ item }">
          {{ item.meta.createdAt | formatDateTime }}
        </template>
        <template v-slot:item.meta.updatedAt="{ item }">
          {{ item.meta.updatedAt | formatDateTime }}
        </template>
        <template v-slot:item.manager.id="{ item }">
          <span v-if="item.manager">{{ item.manager.fullName }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            fab
            text
            small
            :to="{ name: 'order.view', params: { id: item.id} }"
            class="mr-1"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            @click="deleteAction(item)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="deleteDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление данных</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление данных?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteItemConfirm">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'
import { mapActions, mapMutations, mapState } from 'vuex'
import ToastService from '@/service/ToastService'
import { Asc, Desc } from '@/model/SortOrder'
import Page from '@/model/Page'
import _ from 'lodash'
import DropDownList from '@/components/ui/DropDownList.vue'
import { orderStatusColor, orderStatusList } from '@/utils/OrderStatus'
import OrderFilter from '@/model/order/OrderFilter'

export default {
  ...dashboardPageCommonAttributes,
  name: 'OrderListPage',
  components: { DropDownList },
  data: function () {
    return {
      table: {
        selectedItems: [],
        options: {
          sortBy: ['code'],
          sortDesc: [true]
        },
        headers: [
          // { text: 'ID', align: 'left', value: 'id' },
          { text: '', value: 'deliveryType' },
          { text: 'Код', align: 'left', value: 'code' },
          { text: 'Дата', value: 'orderDate' },
          { text: 'До', value: 'deliveryDate' },
          { text: 'Статус', value: 'status' },
          { text: 'Клиент', value: 'customerName' },
          { text: 'Менеджер', value: 'manager.id' },
          // { text: '', value: 'image' },
          // { text: '', value: 'isActive' },
          // { text: 'Цена', value: 'price' },
          // { text: 'Название', value: 'name' },
          { text: 'Сумма', value: 'totalAmount' }
          // { text: 'Создан', value: 'meta.createdAt' },
          // { text: 'Изменен', value: 'meta.updatedAt' },
          // { text: 'Действия', value: 'actions', sortable: false, align: 'right' }
        ],
        filter: {
          search: '',
          orderStatus: [],
          deliveryType: null,
          source: null
        },
        footerProps: {
          itemsPerPageOptions: [10, 15, 30, 50],
          itemsPerPageText: 'Строк на странице:',
          // showCurrentPage:true,
          showFirstLastPage: true
        }
      },
      deleteDialog: {
        show: false,
        value: []
      },
      orderStatusOptions: orderStatusList(),
      deliveryTypeOptions: [
        { text: 'Все', value: null },
        { text: 'Доставка', value: 'Delivery' },
        { text: 'Самовывоз', value: 'Pickup' }
      ],
      sourceOptions: [
        { text: 'Все', value: null },
        { text: 'Сайт', value: 'Site' },
        { text: 'Менеджер', value: 'Manager' }
      ]
    }
  },
  computed: {
    ...mapState('order_list', ['items', 'busy', 'itemsTotalCount', 'filter', 'page', 'showAdvancedSearch']),
    hasSelected() {
      return this.table.selectedItems.length > 0
    }
  },
  watch: {
    'table.options': {
      handler() {
        this.loadTable()
      },
      deep: true
    }
  },
  created() {
    this.table.filter.search = this.filter.search
    this.table.filter.orderStatus = this.orderStatusOptions.filter((o) => this.filter.orderStatus.includes(o.value))

    if (this.filter.source.length > 0) {
      this.table.filter.source = this.sourceOptions.find((o) => this.filter.source.includes(o.value))
    }
    else {
      this.table.filter.source = this.sourceOptions.find((o) => o.value === null)
    }

    if (this.filter.deliveryType.length > 0) {
      this.table.filter.deliveryType = this.deliveryTypeOptions.find((o) => this.filter.deliveryType.includes(o.value))
    }
    else {
      this.table.filter.deliveryType = this.deliveryTypeOptions.find((o) => o.value === null)
    }

    const tableOptions = {
      sortBy: [],
      sortDesc: [],
      page: this.page.page + 1,
      itemsPerPage: this.page.size
    }

    this.page.sort.forEach((s) => {
      tableOptions.sortBy.push(s.field)
      tableOptions.sortDesc.push(s.order === 'Desc')
    })
    this.table.options = tableOptions
  },
  methods: {
    orderStatusColor,
    ...mapMutations('order_list', {
      setPage: 'SET_PAGE',
      setFilter: 'SET_FILTER',
      toggleAdvancedSearch: 'TOGGLE_ADVANCED_SEARCH'
    }),
    ...mapActions('order_list', ['fetchData', 'deleteItem', 'setOrderStatus']),
    loadTable() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.table.options

      const sort = []

      sortBy.forEach((field, index) => {
        sort.push({
          field,
          order: (sortDesc[index]) ? Desc : Asc
        })
      })

      const p = Page.create({
        page: page - 1,
        size: itemsPerPage,
        sort
      })

      this.setPage({ page: p })

      this.fetchData()
    },
    deleteAction(item) {
      this.deleteDialog.value = [item]
      this.deleteDialog.show = true
    },
    deleteSelectedAction() {
      this.deleteDialog.value = this.table.selectedItems
      this.deleteDialog.show = true
    },
    deleteItemConfirm() {
      const ids = this.deleteDialog.value.map((item) => item.id)

      this.deleteItem({ ids })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.deleteDialog.value = []
          this.deleteDialog.show = false
          this.table.selectedItems = []
        })
    },
    setStatusAction(value) {
      const ids = this.table.selectedItems.map((item) => item.id)

      this.setOrderStatus({ ids, orderStatus: value.value })
        .then(() => {
          ToastService.success('Данные сохранены')
          this.table.selectedItems = []
        })
    },
    onInputFilter: _.debounce(function () {
      const deliveryType = []
      const formDeliveryType = this.table.filter.deliveryType?.value ?? null

      if (formDeliveryType !== null) {
        deliveryType.push(formDeliveryType)
      }

      const source = []
      const formSource = this.table.filter.source?.value ?? null

      if (formSource !== null) {
        source.push(formSource)
      }

      const filter = OrderFilter.create({
        ...this.table.filter,
        orderStatus: this.table.filter.orderStatus.map( (o) => o.value),
        deliveryType,
        source
      })

      this.table.options.page = 1

      this.setFilter({ filter })

      this.loadTable()
    }, 500),
    resetFilter() {
      this.table.filter = {
        search: '',
        orderStatus: [],
        deliveryType: null,
        source: null
      }
      this.onInputFilter()
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Заказы'
      }
    }
  }
}
</script>
